import React from "react"
import Layout from "../components/layout"
// import { Link, graphql, useStaticQuery } from "gatsby"
// import "./blog.scss"
import { Seo } from "../components/seo"

const BlogPage = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
  //       edges {
  //         node {
  //           title
  //           slug
  //           publishedDate(formatString: "MMMM Do, YYYY")
  //         }
  //       }
  //     }
  //   }
  // `)
  return (
    <div>
      <Layout>
        <h1>Blog</h1>
        {/* <ol className="posts">
          {data.allContentfulBlogPost.edges.map(edge => (
            <li className="post" key={edge.node.slug}>
              <Link to={`/blog/${edge.node.slug}`}>
                <h2>{edge.node.title}</h2>
                <p>{edge.node.publishedDate}</p>
              </Link>
            </li>
          ))}
        </ol> */}
      </Layout>
    </div>
  )
}

export default BlogPage

export const Head = () => {
  return <Seo title="Blog" />
}
